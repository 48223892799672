import { Helmet } from 'react-helmet-async';
import { Content } from './components/Content';
import { PageProvider } from './providers/PageProvider';
import { useSlugRedirect } from '@hooks/useSlugRedirect';
import { routes } from '@routes';

export const AdminTransactionCodesPage = () => {
  useSlugRedirect(routes.adminTransactionCodesList);
  return (
    <>
      <Helmet>
        <title>Transaction Codes - AutoScript Admin</title>
      </Helmet>

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
