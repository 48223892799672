import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import { TRole } from 'app/types/entities/TRole';
import { TWorkspace } from 'app/types/entities/TWorkspace';

import { Header } from '@components/Header';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { DisclosureSection } from '@components/DisclosureSection';

import { WorkspaceDetailsForm } from './form';
import { TeamCards } from './components/TeamCards';
import { useAPI } from '@hooks/useAPI';
import { WorkspacesService } from 'app/API';

const Wrapper = styled.div`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  position: relative;
`;

export const AdminWorkspaceDetailsPage = () => {
  const { call } = useAPI();
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [workspace, setWorkspace] = useState<TWorkspace>();
  const [teams, setTeams] = useState<TRole[]>([]);
  const [teamsDisclosureOpen, setTeamsDisclosureOpen] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    call(WorkspacesService.adminGetWorkspace({ id: id ?? '' })).then(async (data) => {
      setWorkspace(data.result);
      const teamsResult = await call(WorkspacesService.adminGetTeams({ id: id ?? '' }));
      setTeams(teamsResult.result);
      setIsLoading(false);
    });
  }, []);

  const teamsTitle = 'Teams' + (teams.length > 0 ? ` (${teams.length})` : '');

  return (
    <>
      <Helmet>
        <title>{workspace?.name || 'Workspace'} - AutoScript Admin</title>
      </Helmet>
      <Header title={workspace?.name} />

      {workspace ? (
        <Wrapper>
          {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
          <WorkspaceDetailsForm name={workspace?.name ?? ''} slug={workspace?.slug ?? ''} />

          <div>
            <DisclosureSection
              title={teamsTitle}
              open={teamsDisclosureOpen}
              setOpenState={setTeamsDisclosureOpen}
              loading={false}
            >
              <TeamCards teams={teams} />
            </DisclosureSection>
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};
