import { client } from 'app/http';

export const addMembers = async (id: string, memberIds: string[]) => {
  try {
    const response = await client.patch(`/teams/${id}/members`, { memberIds });
    return response.data;
  } catch (err) {
    console.log('addMembers request failed:', err);
    return [];
  }
};
