import { useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Calendar as CalendarIcon } from '@components/icons/Calendar';

import ErrorIcon from './icons/error.svg';
import LeftIcon from './icons/left.svg';
import RightIcon from './icons/right.svg';

import { TProps } from './types/TProps';
import { months } from './helpers/months';

import { Wrapper } from './styles/Wrapper';
import { PickerHeader } from './styles/PickerHeader';

export const DatePicker = ({
  label,
  onChange,
  onIconClick,
  value = null,
  minDate = new Date(0),
  maxDate = new Date(),
  required = false,
  disabled = false,
  error = false,
  showTimeSelect = false,
  className = 'form-input',
  dateFormat = 'MM/dd/yyyy',
}: TProps) => {
  const defaultTitle = `${label}${required ? ' *' : ''}`;
  const [title, setTitle] = useState(defaultTitle);

  const onFocus = () => {
    setTitle('mm/dd/yyyy');
  };

  const onBlur = () => {
    setTitle(defaultTitle);
  };

  const renderIcon = () => {
    if (error) {
      return <ErrorIcon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
    }
    return <CalendarIcon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
  };

  return (
    <Wrapper className={className}>
      {renderIcon()}

      <ReactDatePicker
        dateFormat={dateFormat}
        placeholderText={title}
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => (
          <PickerHeader>
            <LeftIcon onClick={decreaseMonth} />
            <span className="current-month">{months[date.getMonth()]}</span>
            <RightIcon onClick={increaseMonth} />
          </PickerHeader>
        )}
        selected={value}
        onChange={(date) => onChange(date)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Wrapper>
  );
};
