import { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { adminRefundJob } from '@queries/admin/refundJob';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  max-width: 400px;

  .content {
    padding: 30px 0 10px;
  }

  .input-wrapper {
    padding: 0 0 20px;

    .label {
      padding: 0 0 10px;
      color: var(--858DBD, #858dbd);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
    }

    input {
      height: 30px;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
    text-align: center;
  }

  p.description {
    color: #00122d;
    margin: 30px 0;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
  price: number;
};

export const AdminRefundJobModal = ({ onCancel, onSuccess, id, price }: TProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState('');
  const isSubmitDisabled = !reason.length;
  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Refund Job</h2>
      <div className="content">
        <div className="input-wrapper">
          <div className="label">Job Price</div>
          <p>${(price / 100).toFixed(2)}</p>
        </div>

        <div className="input-wrapper">
          <div className="label">Reason</div>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}
            label="Type the reason of refund"
            required={true}
            value={reason}
          />
        </div>
      </div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsLoading(true);
          await adminRefundJob(id, reason);
          setIsLoading(false);
          onSuccess();
        }}
      >
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitDisabled}>
            Refund
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
