import { useEffect, useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';
import { convertDuration } from '@helpers/convertDuration';
import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { getPaymentMethodsJobSubmit } from '@queries/billing-account/getPaymentMethodsJobSubmit';
import { TProps } from '@components/Modal/UnlockJobModal/types/TProps';
import { Wrapper } from '@components/Modal/UnlockJobModal/styles/Wrapper';
import { StyledButton } from '@components/Modal/UnlockJobModal/styles/StyledButton';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { RefreshBalance } from '@components/Modal/UnlockJobModal/icons/RefreshBalance';
import { UnlockModalErrors } from '@components/Modal/UnlockJobModal/components/errors';
import { useAPI } from '@hooks/useAPI';
import { BillingService } from 'app/API';

export function UnlockJobModal({ onCancel, onSuccess, id, name }: TProps) {
  const { updateMe, organization, workspace } = useAuthContext();
  const { call } = useAPI();

  const [price, setPrice] = useState<{
    duration?: number;
    alreadyPaid?: number;
    totalJobCost?: number;
    amount?: number;
  }>({ duration: 0, alreadyPaid: 0, totalJobCost: 0, amount: 0 });
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [balanceIsRefreshing, setBalanceIsRefreshing] = useState(false);

  const balance = organization?.billingAccount?.currentBalance ?? 0;
  const notEnoughFunds = (price?.amount ?? 0) > balance;
  const noBillingAccount = !organization?.billingAccount?.id;

  useEffect(() => {
    setDataLoaded(false);
    getPaymentMethodsJobSubmit(organization?.billingAccount?.id || '').then((methods) => {
      setPaymentMethodId(methods?.find((item) => item.default)?.id);
      updateMe().then(async () => {
        const priceData = await call(BillingService.calculateJobUnlockPricing({ jobId: id }));
        if (priceData?.result) {
          setPrice(priceData?.result);
        }
        setDataLoaded(true);
      });
    });
  }, []);

  const loading = !dataLoaded;
  const error = noBillingAccount || (!paymentMethodId && notEnoughFunds) || notEnoughFunds;

  const unlock = async () => {
    await call(BillingService.unlockJob({ wsid: workspace?.id ?? '', id }));
  };

  return (
    <Wrapper>
      {loading && <AbsoluteSpinner overlay={true} />}
      <h2 data-testid="priceModalH2">Unlock Job</h2>

      <div className="title">Job title</div>
      <div className="title-value">{name}</div>

      <div className="title">Current balance</div>
      <div className={`title-value ${balanceIsRefreshing ? 'balance-refresh' : ''}`}>
        {formatCentsPrice(balance)}
        <RefreshBalance
          onClick={() => {
            if (balanceIsRefreshing) {
              return;
            }
            setBalanceIsRefreshing(true);

            updateMe()
              .then(() => {
                setBalanceIsRefreshing(false);
                setDataLoaded(true);
              })
              .catch(() => {
                setBalanceIsRefreshing(false);
                setDataLoaded(true);
              });
          }}
        />
      </div>

      <div className="price-description">
        <div>
          <div className="dflex noBorder">
            <span>Duration:</span>
            {convertDuration(price?.duration ?? 0)}
          </div>

          <div className="dflex">
            <span>Total cost:</span>
            {formatCentsPrice(price?.totalJobCost ?? 0)}
          </div>

          <div className="dflex">
            <span>Already paid:</span>
            {formatCentsPrice(price?.alreadyPaid ?? 0)}
          </div>

          <div className="dflex bold">
            <span>In order to unlock this job you will be charged:</span> {formatCentsPrice(price?.amount ?? 0)}
          </div>
        </div>
      </div>

      <div className={`errorsWrapper ${error ? 'active' : ''}`}>
        <UnlockModalErrors
          setBalanceIsRefreshing={setBalanceIsRefreshing}
          price={price}
          onCancel={onCancel}
          dataLoaded={dataLoaded}
          onSuccess={onSuccess}
          jobId={id}
          jobName={name}
          isSubmitting={loading}
          paymentMethodId={paymentMethodId}
        />
      </div>

      <div className="buttonWrapper">
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <StyledButton
          variant="contained"
          disabled={loading || error}
          onClick={async () => {
            setDataLoaded(false);
            await unlock();
            onSuccess();
          }}
        >
          Unlock
        </StyledButton>
      </div>
    </Wrapper>
  );
}
