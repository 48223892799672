import styled from 'styled-components';

export const Wrapper = styled.div`
  width: calc(100% - 240px);
  left: 240px;

  .paginationBlock {
    display: flex;
    grid-gap: 10px;
    flex: 1 0 auto;
    margin-right: 1rem;
  }

  .dflex {
    display: flex;
    grid-gap: 30px;
    width: 100%;
  }
`;
