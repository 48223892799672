export const Microphone = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.62946 11.25C8.51585 11.25 10.0446 9.73929 10.0446 7.875V3.375C10.0446 1.51071 8.51585 0 6.62946 0C4.74308 0 3.21429 1.51071 3.21429 3.375V7.875C3.21429 9.73929 4.74308 11.25 6.62946 11.25ZM13.2589 7.83482C13.2589 7.74643 13.1866 7.67411 13.0982 7.67411H11.8929C11.8045 7.67411 11.7321 7.74643 11.7321 7.83482C11.7321 10.6533 9.44799 12.9375 6.62946 12.9375C3.81094 12.9375 1.52679 10.6533 1.52679 7.83482C1.52679 7.74643 1.45446 7.67411 1.36607 7.67411H0.160714C0.0723214 7.67411 0 7.74643 0 7.83482C0 11.2239 2.5433 14.0203 5.82589 14.4161V16.4732H2.90692C2.6317 16.4732 2.41071 16.7605 2.41071 17.1161V17.8393C2.41071 17.9277 2.46696 18 2.53527 18H10.7237C10.792 18 10.8482 17.9277 10.8482 17.8393V17.1161C10.8482 16.7605 10.6272 16.4732 10.352 16.4732H7.35268V14.4261C10.6734 14.0645 13.2589 11.252 13.2589 7.83482Z"
        fill="white"
      />
    </svg>
  );
};
