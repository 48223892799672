import * as Sentry from '@sentry/react';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';
import { fetchAudioDurationFromFileReader } from '@helpers/repositoryAudioHelpers';
import { RepositoriesService } from 'app/API';
import { sanitizeFileName } from '@helpers/sanitizeFileName';

export type RepositoryAttachment = {
  id: string;
  fileName: string;
  fileSize: number;
  fileExtension: string;
  createdAt: string;
  updatedAt: string;
  weight: number;
  isAudio: boolean;
  audioDuration?: number;
};

export const handleChangeStatusHandler = (
  organizationSlug: string | null,
  repositoryId: string | null,
  allFiles: TFileWithMeta[],
  setIsAllFilesLoaded: (item: boolean) => void,
  filesCountRef: React.MutableRefObject<number>,
  setFilesCount: (item: number) => void,
  newFiles: TFileWithMeta[],
  setNewFiles: (item: TFileWithMeta[]) => void,
  call: (v: Promise<any>) => Promise<any>,
) => {
  return (file: TFileWithMeta, status: string) => {
    // console.log(file.meta.name, 'status', status, 'uploadFilesList', uploadFilesList, 'allFiles', allFiles);

    const files = [...allFiles];
    const index = newFiles.findIndex((f) => f === file);
    const uploadFilesList = index === -1 ? [...newFiles.filter((f) => f !== file), file] : [...newFiles];
    const notLoadedFiles = files.find((item) => item.meta.status === 'uploading');
    setIsAllFilesLoaded(!notLoadedFiles);

    const performReady = (f: TFileWithMeta) => {
      setFilesCount(filesCountRef.current + 1);
      f.restart();

      setNewFiles([...uploadFilesList]);
      return;
    };

    if (status === 'ready') {
      setFilesCount(filesCountRef.current + 1);
      file.restart();

      setNewFiles([...uploadFilesList]);
      return;
    }

    if (status === 'error_upload') {
      return;
    }

    if (status === 'getting_upload_params') {
      return;
    }

    if (status === 'preparing' || status === 'started') {
      setNewFiles([...uploadFilesList]);
      return;
    }

    if (status === 'uploading') {
      const index = uploadFilesList.findIndex((f) => f === file);
      uploadFilesList[index].meta = file.meta;
      setNewFiles([...uploadFilesList]);
      return;
    }

    if (status === 'done') {
      setFilesCount(filesCountRef.current - 1);
      const readyFiles = files.filter((item) => item.meta.status === 'ready');
      if (readyFiles.length > 0) {
        readyFiles.forEach((f) => performReady(f));
      }
      try {
        (async () => {
          const index = uploadFilesList.findIndex((f) => f === file);
          const weight = allFiles.findIndex(
            (f) => f?.file?.name === file?.file.name && f?.meta.uploadedDate === file?.meta.uploadedDate,
          );
          if (index === -1) {
            console.error('Uploaded file not found in uploadedFilesList', file, uploadFilesList);
            return;
          }

          const isAudio = EACCEPTED_AUDIO_FORMATS.includes('.' + file.file.name.split('.').pop()?.toLowerCase());

          let audioDuration = 0;

          if (isAudio) {
            audioDuration = await fetchAudioDurationFromFileReader(file.file);
          }
          const response = await call(
            RepositoriesService.createAttachments({
              id: repositoryId ?? '',
              requestBody: {
                repositoryId: repositoryId ?? '',
                fileName: sanitizeFileName(file.file.name),
                fileSize: file.file.size?.toString() || '0',
                weight,
                isAudio,
                audioDuration: isAudio ? Math.ceil(audioDuration) : 0,
                organizationSlug: organizationSlug ?? '',
              },
            }),
          );
          if (!response) {
            uploadFilesList[index].meta.duration = 0;
            uploadFilesList[index].meta.status = 'error_upload';
            setNewFiles([...uploadFilesList]);
            return;
          }
          uploadFilesList[index].meta.Id = response;
          uploadFilesList[index].meta.duration = isAudio ? Math.ceil(audioDuration) : undefined;
          uploadFilesList[index].meta.status = 'done';
          setNewFiles([...uploadFilesList]);
        })();
      } catch (e) {
        Sentry.captureMessage(`Error while uploading file for repository ${repositoryId}`, {
          extra: { file, e, repositoryId },
        });
        Sentry.captureException(e);
        console.error(e);
      }
    }
  };
};

export const mapAttachmentsToIFileWithMeta = (attachments: RepositoryAttachment[]): TFileWithMeta[] => {
  return attachments.map((attachment) => {
    return {
      meta: {
        Id: attachment.id,
        name: attachment.fileName,
        size: Number(attachment.fileSize),
        status: 'done',
      },
      file: null,
      restart: () => {},
      cancel: () => {},
    };
  }) as never[];
};
