import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash-es';

import { useAuthContext } from '@providers/AuthProvider';
import { checkIsSlugAvailable } from '@queries/organizations/checkIsSlugAvailable';

import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { ModalWrapper } from './styles';
import { routes } from '@routes';
import { useNavigate } from 'react-router';
import { sanitizeSlug } from '@helpers/sanitizeSlug';
import { useAPI } from '@hooks/useAPI';
import { OrganizationsService } from 'app/API';

type TProps = {
  onCancel: () => void;
};

export const NewOrganizationModal = ({ onCancel }: TProps) => {
  const { call } = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSlugGenerated, setIsSlugGenerated] = useState(true);

  const { updateMe, setOrganization } = useAuthContext();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<{ name: string; slug: string; image: string | null }>({
    name: '',
    slug: '',
    image: null,
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    slug: '',
    image: '',
  });

  useEffect(() => {
    setIsSubmitting(true);
    const fetchData = async () => {
      setFormValues({ ...formValues });
      setIsSubmitting(false);
    };
    fetchData();
  }, []);

  const [queryError, setQueryError] = useState('');

  useEffect(() => {
    const debounced = debounce(async () => {
      setIsSubmitting(true);
      // only a-z 0-9 and - are allowed
      const regex = /^[a-z0-9-]+$/;
      if (!formValues.slug.match(regex)) {
        setFormErrors({ ...formErrors, slug: 'Only a-z, 0-9 and - are allowed' });
        setIsSubmitting(false);
        return;
      }

      const data = await checkIsSlugAvailable(formValues.slug);
      data.available
        ? setFormErrors({ ...formErrors, slug: '' })
        : setFormErrors({ ...formErrors, slug: 'This URL is already in use' });
      setIsSubmitting(false);
    }, 500);

    debounced();
    return () => debounced.cancel();
  }, [formValues.slug]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const data = await call(
      OrganizationsService.create({ requestBody: { name: formValues.name, slug: formValues.slug } }),
    );
    if (!data.error && data.slug) {
      setIsSubmitting(false);
      const me = await updateMe();
      const newOrg = me.organizations.find((org) => org.slug === data.slug);
      if (newOrg) {
        setOrganization(newOrg);
        navigate(routes.dashboard.make(data.slug));
      }
      onCancel();
      return;
    }
    if (data.error) {
      setIsSubmitting(false);
      setQueryError(data.error);
    }
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Create New Organization</h2>

      <div className="workspaceSettings-inputWrapper">
        <div className="inputContainer">
          <label htmlFor="title">Organization Name</label>
          <Input
            label="Name"
            name="title"
            value={formValues.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isSlugGenerated) {
                const slug = sanitizeSlug(e.target.value);
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                  slug,
                });
              } else {
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                });
              }
            }}
            error={!!formErrors.name}
          />
        </div>

        <div className="inputContainer">
          <label htmlFor="slug">Organization ID</label>
          <Input
            label="Organization ID"
            name="slug"
            value={formValues.slug}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({ ...formValues, slug: e.target.value });
              setIsSlugGenerated(false);
            }}
            error={!!formErrors.slug}
          />
        </div>

        <div className="workspaceSettings-errorMessage">{formErrors.slug}</div>
        {queryError && <div className="workspaceSettings-errorMessage largeText">{queryError}</div>}
      </div>
      <div className="buttonContainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          disabled={isSubmitting || !!(formErrors.slug || formErrors.name) || !(formValues.slug && formValues.name)}
          onClick={() => handleSubmit()}
        >
          Create
        </Button>
      </div>
    </ModalWrapper>
  );
};
