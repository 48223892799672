import React, { useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useSlugRedirect } from '@hooks/useSlugRedirect';

import { EmailIcon } from '@components/icons/Email';
import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { Select } from '@components/form-elements/Select';
import { TRole } from 'app/types/entities/TRole';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TrashBin } from '@components/icons/TrashBin';

import { PlusBalanceIcon } from '@pages/Admin/OrganizationsListPage/components/Content/styles/PlusBalanceIcon';

import { EErrorMessages } from '@constants/errorMessages';
import { OrganizationsService } from '../../API';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  width: 300px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    padding: 20px 0 0;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    .select {
      height: 40px;
    }

    .add-more {
      cursor: pointer;
      margin: 5px;
    }

    .csv {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #40608f;
      text-decoration: underline;
      cursor: pointer;
    }

    .additional-team {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const InviteUserModal = ({ onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const [email, setEmail] = useState('');
  const [teamIds, setTeamIds] = useState<string[]>(['']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organization } = useAuthContext();
  useSlugRedirect(routes.invitations);

  const { openModal } = useModalContext();

  const oneMoreTeam = async () => {
    // if last select is empty
    if (!teamIds[teamIds.length - 1]) {
      return;
    }
    const newTeamIds = [...teamIds, ''];
    setTeamIds(newTeamIds);
  };

  const changeTeamIds = async (index: number, value: string | undefined) => {
    const newTeamIds = [...teamIds];
    if (value) {
      newTeamIds[index] = value;
    } else {
      newTeamIds.splice(index, 1);
    }
    setTeamIds(newTeamIds);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      const teamIdsFiltered = teamIds.filter((teamId: string) => teamId && teamId !== '');
      await call(
        OrganizationsService.createInvitation({
          oid: organization?.id ?? '',
          requestBody: { email, teamIds: teamIdsFiltered },
        }),
      );
      setIsSubmitting(false);
      onSuccess();
    } catch (error) {
      toast.error(EErrorMessages.DEFAULT);
      setIsSubmitting(false);
    }
  };

  const firstOptions: TRole[] = (organization?.orgTeams ?? []).filter((team) => {
    return !teamIds.includes(team.id) || teamIds[0] === team.id;
  });

  const teams = (firstOptions || [])
    .map((role) => ({ value: role.id, label: role.name }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  const openInviteUsersByCsvModal = () => {
    openModal(ModalNamesEnum.InviteUsersByCsvModal, {
      onSuccess,
    });
  };

  return (
    <Wrapper>
      <h2>Invite Member</h2>
      <form action="#" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <Input
            icon={EmailIcon}
            required
            value={email}
            type="email"
            label="Email"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.toLowerCase())}
            error={isSubmitting && !email}
          />
          <div className="additional-team">
            <Select
              value={teamIds[0]}
              options={teams}
              onChange={(_, value) => changeTeamIds(0, value)}
              label="Team"
              required
              name="role"
            />
            {teamIds.length < 5 ? (
              <PlusBalanceIcon style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={oneMoreTeam} />
            ) : null}
          </div>

          {teamIds.map((teamId, index) => {
            if (index === 0) {
              return;
            }
            const options: TRole[] = (organization?.orgTeams ?? []).filter((team) => {
              return !teamIds.includes(team.id) || teamIds[index] === team.id;
            });
            return (
              <div key={teamId} className="additional-team">
                <Select
                  key={`team-${index}`}
                  value={teamIds[index]}
                  options={options.map((team) => ({ value: team.id, label: team.name })) ?? []}
                  onChange={(_, value) => changeTeamIds(index, value)}
                  label="Team"
                  required
                  name="role"
                />
                <TrashBin
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    setTeamIds((prev) => prev.filter((v) => v !== teamId));
                  }}
                />
              </div>
            );
          })}

          <div className="csv" onClick={openInviteUsersByCsvModal}>
            Bulk invite by CSV
          </div>
        </div>

        <div className="buttonWrapper">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={!teamIds.length || !teamIds[0].length || !email}>
            Invite Member
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};
