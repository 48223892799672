import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';
import { getBillingAccount } from '@queries/billing-account/getBillingAccount';
import { getPaymentMethods } from '@queries/billing-account/getPaymentMethods';
import { formatPrice } from '@helpers/formatPrice';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { useAPI } from '@hooks/useAPI';
import { PaymentService } from 'app/API';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TPaymentMethod } from 'app/types/entities/TPaymentMethod';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 635px;
  max-height: 90vh;

  @media (max-height: 870px) {
    overflow: auto;
  }

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .textarea {
      margin: 0 0 20px;
    }
  }

  .card {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .rates {
    display: flex;
    gap: 5px;

    .rate {
      border-radius: 14px;
      padding: 2px;

      &.active {
        background: linear-gradient(to right, #7aabf3, #2765be);
      }

      &.current .current {
        display: flex;
      }

      .current {
        display: none;
        width: 130px;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px 10px 0 0;
        color: var(--ffffff, #fff);
        background: var(--40608-f, #40608f);
        top: 0;
        left: 0;
        position: absolute;
        text-transform: uppercase;

        text-align: center;
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 14.4px */
      }

      .rate-white {
        border-radius: 12px;
        background: #ffffff;
        padding: 3px;
      }

      &.current .rate-inner {
        padding-top: 10px;
      }

      .rate-inner {
        border-radius: 10px;
        background: var(--f-8-fafb, #f8fafb);
        display: flex;
        flex-direction: column;
        width: 130px;
        height: 110px;
        padding: 0 10px;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
      }

      .bronze {
        background: var(--f-1-f-5-fb, #f1f5fb);
      }

      .silver {
        background: var(--e-4-ebf-5, #e4ebf5);
      }

      .gold {
        background: var(--d-5-def-2, #d5def2);
      }

      .rate-name {
        color: var(--40608-f, #40608f);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
        text-transform: uppercase;
      }

      .rate-requirements,
      .rate-price {
        color: var(--40608-f, #40608f);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        &.striked {
          text-decoration: line-through;
        }

        &.promo {
          color: #ff2f2f;
          font-weight: 700;
        }
      }
    }
  }

  .range-wrapper {
    position: relative;

    .only-x-left {
      position: absolute;
      z-index: 200;
      left: 0;
      top: -30px;
      color: var(--40608-f, #40608f);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      padding: 5px;
      border-radius: 5px;
      width: 162px;
      transform: translateX(-73px);
      background-color: var(--ffffff, #fff);
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);

      &::after {
        content: ' ';
        position: absolute;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: #fff transparent transparent;
        top: 41px;
        left: 50%;
        z-index: 10000;
      }
    }
  }

  .range {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    height: 4px;
    margin: 30px 0 10px;
    width: 100%;
    outline: none;

    &:disabled {
      filter: grayscale(1);
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      transition: all ease 200ms;
      background: #e4ebf5;
      border-radius: 2px;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      transition: all ease 200ms;
      background: #e4ebf5;
      border-radius: 2px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      transition: all ease 200ms;
      background: #e4ebf5;
      border-radius: 2px;
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 0 2px #eee;
      border: 3px solid #ffffff;
      border-radius: 12px;
      background: #2765be;
      background: var(--main-gradient, linear-gradient(90deg, #7aabf3 0%, #2765be 100%));
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 0 2px #eee;
      border: 3px solid #ffffff;
      border-radius: 12px;
      background: #2765be;
      background: var(--main-gradient, linear-gradient(90deg, #7aabf3 0%, #2765be 100%));
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      cursor: pointer;
      box-shadow: 0 0 2px #eee;
      border: 3px solid #ffffff;
      border-radius: 12px;
      background: #2765be;
      background: var(--main-gradient, linear-gradient(90deg, #7aabf3 0%, #2765be 100%));
    }

    &::-webkit-slider-thumb {
      margin-top: -7px;
    }

    &::-ms-thumb {
      margin-top: 1px;
    }

    &::-moz-range-progress {
      border-radius: 2px;
      background: var(--main-gradient, linear-gradient(90deg, #7aabf3 0%, #2765be 100%));
    }

    &::-webkit-progress-value {
      border-radius: 2px;
      background: var(--main-gradient, linear-gradient(90deg, #7aabf3 0%, #2765be 100%));
    }

    &::-ms-fill-lower {
      background: #e4ebf5;
      border: 0 solid #010101;
      border-radius: 6px;
      box-shadow: 0 0 0 #000000;
    }

    &::-ms-fill-upper {
      background: #e4ebf5;
      border: 0 solid #010101;
      border-radius: 6px;
      box-shadow: 0 0 0 #000000;
    }

    &:focus::-ms-fill-lower {
      background: #e4ebf5;
    }

    &:focus::-ms-fill-upper {
      background: #e4ebf5;
    }
  }

  .amount-line {
    display: flex;
    margin: 20px 0 0;
    gap: 13px;

    .balance {
      color: var(--00122-d, #00122d);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      padding: 8px 0 0;

      .striked {
        text-decoration: line-through;
      }

      .promo {
        color: #ff2f2f;
        font-weight: 700;
      }
    }

    .input-wrapper {
      width: 136px;

      .label {
        white-space: nowrap;
      }
    }

    .new-rate {
      .label {
        font-weight: 700;
      }
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    &.red {
      color: #ff2f2f;
      font-weight: 500;
    }

    &.special {
      color: #40608f;
      font-weight: 500;
    }

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  billingAccountId: string;
  onCancel: () => void;
  onSuccess: () => void;
  initialAmount?: number;
};

export const TopUpCreditBalanceModal = ({ billingAccountId, onCancel, onSuccess, initialAmount = 10 }: TProps) => {
  const { closeModal, openModal } = useModalContext();
  const { call } = useAPI();
  let initAmount = initialAmount;
  if (initAmount < 10) {
    initAmount = 10;
  }
  const [amount, setAmount] = useState(initAmount);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();
  const [rate, setRate] = useState(parseFloat((billingAccount?.postProcessingASRRate ?? 0).toString()));
  const [promoRate, setPromoRate] = useState(0);
  const [resultAmount, setResultAmount] = useState((billingAccount?.currentBalance ?? 0) / 100 + 10);
  const [paymentMethods, setPaymentMethods] = useState<{
    default: TPaymentMethod;
    all: TPaymentMethod[];
  }>();
  const [isLoading, setIsLoading] = useState(false);

  const initialRate = parseFloat((billingAccount?.postProcessingASRRate ?? 0).toString());
  const onlyXLeftPosition = ((amount - 10) / 2570) * 100;
  const nextTierLeft =
    rate > 10 && resultAmount > 2200
      ? Math.ceil(2500 - resultAmount)
      : rate > 12 && resultAmount > 900
        ? Math.ceil(1200 - resultAmount)
        : Math.ceil(675 - resultAmount);
  const nextTierName =
    rate > 10 && resultAmount > 2200 ? 'Business' : rate > 12 && resultAmount > 900 ? 'Pro' : 'Freelancer';
  const showOnlyXLeft =
    (rate > 10 && resultAmount > 2200) || (rate > 12 && resultAmount > 900) || (rate > 13.5 && resultAmount > 375);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const billingAccount = await getBillingAccount(billingAccountId);
      setBillingAccount(billingAccount);
      const paymentMethods = await getPaymentMethods(billingAccountId);

      setPaymentMethods({
        default: paymentMethods.find((item) => item.default) || paymentMethods[0],
        all: paymentMethods,
      });
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!billingAccount) {
      return;
    }
    const defaultRate = parseFloat((billingAccount?.postProcessingASRRate ?? 0).toString());
    const currentBalance = (billingAccount.currentBalance ?? 0) / 100;
    const newAmount = amount + currentBalance;

    // If fixed rate is set
    if (billingAccount?.fixedRate) {
      setRate(defaultRate);
      setPromoRate(0);
      setResultAmount(newAmount);
      return;
    }

    // Otherwise, calculate the rate based on the amount
    let newRate = defaultRate;
    if (newAmount >= 2500) {
      newRate = 10;
    } else if (newAmount >= 1200) {
      newRate = 12;
    } else if (newAmount >= 675) {
      newRate = 13.5;
    }
    setResultAmount(newAmount);
    setRate(newRate);
  }, [amount, billingAccount]);

  const onSubmit = async () => {
    setIsLoading(true);
    const result = await call(
      PaymentService.createPaymentIntent({
        baId: billingAccountId,
        requestBody: {
          amount: amount * 100,
          balanceTopup: true,
        },
      }),
    );
    if (['succeeded', 'processing'].includes(result.status)) {
      openModal(ModalNamesEnum.TransactionStatus, {
        chargeId: result.latest_charge,
        pics: result.client_secret,
        onSuccess,
      });
      setIsLoading(false);
      return;
    }
    // 3D Secure
    // todo: maybe make it open in a modal with an iframe???
    if (result.status === 'requires_action' && result.next_action?.type === 'redirect_to_url') {
      setIsLoading(false);
      window.location = result.next_action.redirect_to_url.url;
      return;
    }
    if ((result.status ?? '').includes('requires_')) {
      // requires_
      openModal(ModalNamesEnum.Status, {
        title: 'Payment Status',
        message: (
          <>
            Something went wrong. Please try again later <br /> (Status code: {result.status})
          </>
        ),
        status: 'error',
        buttonMessage: 'Ok',
        onSuccess,
      });
      setIsLoading(false);
      return;
    }
    // canceled?
    setIsLoading(false);
    closeModal();
    onSuccess();
  };

  const isSubmitDisabled = isLoading || !paymentMethods?.default || amount < 10;

  const renderPaymentMethod = () => {
    if (isLoading) {
      return <div className="card empty">Loading...</div>;
    }
    if (!paymentMethods?.default) {
      return <div className="card empty red">Not set</div>;
    }
    return <div className="card">Card: **** {paymentMethods.default.last4}</div>;
  };

  const renderASRRate = () => {
    if (billingAccount?.fixedRate) {
      return <div className="rate">${rate}/audio hour</div>;
    }
    return (
      <div className="range-asr-wrapper">
        <div className="rates">
          <div className={`rate ${rate === 15 ? 'active' : ''} ${initialRate === 15 ? 'current' : ''}`}>
            <div className="rate-white">
              <div className="rate-inner">
                <div className="current">Current</div>
                <div className="rate-name">Starter</div>
                <div className="rate-requirements">&nbsp;</div>
                <div className="rate-price">$15.00/audio hour</div>
              </div>
            </div>
          </div>

          <div
            className={`rate ${rate === 13.5 ? 'active' : ''} ${
              initialRate === 13.5 || initialRate === 12.5 ? 'current' : ''
            }`}
          >
            <div className="rate-white">
              <div className="rate-inner bronze">
                <div className="current">Current</div>
                <div className="rate-name">Freelancer</div>
                <div className="rate-requirements">Over $675.00</div>
                <div className="rate-price">$13.50/audio hour</div>
              </div>
            </div>
          </div>

          <div
            className={`rate ${rate === 12 ? 'active' : ''} ${
              initialRate === 12 || initialRate === 11 ? 'current' : ''
            }`}
          >
            <div className="rate-white">
              <div className="rate-inner silver">
                <div className="current">Current</div>
                <div className="rate-name">Pro</div>
                <div className="rate-requirements">Over $1,200.00</div>
                <div className="rate-price">$12.00/audio hour</div>
              </div>
            </div>
          </div>

          <div
            className={`rate ${rate === 10 ? 'active' : ''} ${
              initialRate === 10 || initialRate === 9 ? 'current' : ''
            }`}
          >
            <div className="rate-white">
              <div className="rate-inner gold">
                <div className="current">Current</div>
                <div className="rate-name">Business</div>
                <div className="rate-requirements">Over $2,500.00</div>
                <div className="rate-price">$10.00/audio hour</div>
              </div>
            </div>
          </div>
        </div>

        <div className="range-wrapper">
          <div
            className="only-x-left"
            style={{ display: `${showOnlyXLeft ? 'block' : 'none'}`, left: `${onlyXLeftPosition}%` }}
          >
            Only ${nextTierLeft}
            <br />
            to reach the {nextTierName} tier!
          </div>
          <input
            id="steps-range"
            type="range"
            min={10}
            max={2500}
            value={amount}
            step={1}
            onChange={(e) => setAmount(Number(e.target.value))}
            className="range w-full h-2 bg-[#E4EBF5] rounded-lg appearance-none cursor-pointer"
          />
        </div>
      </div>
    );
  };

  const renderAmountLine = () => {
    return (
      <div className="amount-line">
        <div className="input-wrapper">
          <div className="label">Amount (USD) *</div>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!e.target.value.length) {
                setAmount(0);
                return;
              }
              setAmount(parseInt(e.target.value, 10));
            }}
            label="Amount"
            value={amount}
          />
        </div>
        <div className="input-wrapper">
          <div className="label">Balance after top-up</div>
          <div className="balance">{formatPrice(resultAmount)}</div>
        </div>
        <div className="input-wrapper">
          <div className="label">Estimated audio hours</div>
          <div className="balance">
            {promoRate && rate ? (
              <>
                <span>{Math.floor(resultAmount / rate)}</span>
              </>
            ) : rate ? (
              Math.floor(resultAmount / rate)
            ) : (
              'Loading...'
            )}
          </div>
        </div>
        <div className="input-wrapper new-rate">
          <div className="label">Your rate / audio hour</div>
          <div className="balance">
            {promoRate && rate ? (
              <>
                <span>{`$${rate.toFixed(2).toString()}`}</span>
              </>
            ) : rate ? (
              `$${rate.toFixed(2).toString()}`
            ) : (
              'Loading...'
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Prepaid Credit Balance</h2>

      <div className="input-wrapper">
        <div className="label">Payment method</div>
        {renderPaymentMethod()}
      </div>
      <div className="input-wrapper">
        <div className="label">Rate</div>
        {renderASRRate()}
      </div>

      {renderAmountLine()}

      <div className="description">* Note: minimum top up amount is $10</div>
      <div className="description">** Balance update may take up to 1 minute after the payment</div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} onClick={onSubmit}>
          Pay now
        </Button>
      </div>
    </ModalWrapper>
  );
};
