import styled from 'styled-components';

import { Select } from '@components/form-elements/Select';

export const StyledSelect = styled(Select)`
  width: 55px;
  height: 30px;

  .select {
    padding: 0;
    height: 30px;
    display: flex;
    width: 55px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row-reverse;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px #40608f;

      .label {
        color: #40608f;
      }

      .icon {
        path {
          fill: #40608f;
        }
      }
    }

    .heightHack {
      display: none;
    }
  }

  .optionsWrapper {
    top: auto;
    bottom: 0;
  }

  .option {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #858dbd;
    height: 30px;
    align-items: center;
  }

  .optionsLabel {
    display: none;
  }

  .icon--closed {
    position: static;
    transform: none;
    width: 1rem;
    padding: 0.1rem;
  }

  .label {
    position: static;
    transform: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #858dbd;
  }
`;
