import styled from 'styled-components';
import { useNavigate } from 'react-router';

import { Button } from '@components/form-elements/buttons/Button';
import { routes } from '@routes';

const StyledSwitchButtonWrapper = styled.div`
  display: flex;

  .button {
    border-radius: 0;
    background: #f8fafb;
    color: #40608f;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    width: max-content;
    padding: 8px 15px;
  }

  .active {
    background: #d5def2;
  }

  .left {
    border-radius: 5px 0 0 5px;
  }

  .right {
    border-radius: 0 5px 5px 0;
  }
`;

type TProps = {
  currentTab: string;
};

export const Tabs = ({ currentTab }: TProps) => {
  const navigate = useNavigate();
  return (
    <StyledSwitchButtonWrapper key="tabSwitcher">
      <Button
        onClick={() => {
          navigate(routes.adminSMSCountries.make());
        }}
        className={`button left ${currentTab === 'SMS Countries' ? 'active' : ''}`}
      >
        SMS Countries
      </Button>
      <Button
        onClick={() => {
          navigate(routes.adminSMSWhitelist.make());
        }}
        className={`button right ${currentTab === 'SMS Whitelist' ? 'active' : ''}`}
      >
        SMS Whitelist
      </Button>
    </StyledSwitchButtonWrapper>
  );
};
