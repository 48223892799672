import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';

import { AdminService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { downloadCSV } from '@helpers/downloadCSV';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TJobReport } from 'app/types/entities/TJobReport';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';

type TState = {
  jobsReports: TPaginatedResponse<TJobReport>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  page: number;
  setPage: (v: number) => void;
  reloadPage: () => void;
  exportCSV: () => void;
  showResubmitJobModal: (id: string) => void;
  showMoveJobToWSModal: (id: string, workspaceId: string) => void;
  showRefundJobModal: (data: TJobReport) => void;
  jobsFilter: TJobsFilter;
  setJobsFilter: (v: TJobsFilter) => void;
  statusFilter: string[];
  setStatusFilter: (v: string[]) => void;
};

const PageContext = createContext<TState>({
  jobsReports: {
    data: [],
    count: 0,
  },
  isLoading: false,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  page: 1,
  setPage: () => null,
  reloadPage: () => null,
  exportCSV: () => null,
  showResubmitJobModal: () => null,
  showMoveJobToWSModal: () => null,
  showRefundJobModal: () => null,
  jobsFilter: {},
  setJobsFilter: () => null,
  statusFilter: [],
  setStatusFilter: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [jobsReports, setJobsReports] = useState<TPaginatedResponse<TJobReport>>({ data: [], count: 0 });
  const [jobsFilter, setJobsFilter] = useState<TJobsFilter>({
    from: undefined,
    to: undefined,
  });
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  const reloadPage = async () => {
    closeModal();
    reloadData();
  };

  const reloadData = async () => {
    setIsLoading(true);
    const fromParam = Math.floor(Number(jobsFilter.from) / 1000) || '';
    const toParam = Math.floor(Number(jobsFilter.to) / 1000) || '';
    const data = await call(
      AdminService.getJobsReport({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        statuses: statusFilter.join(','),
        search,
        from: `${fromParam}`,
        to: `${toParam}`,
      }),
    );
    setJobsReports(data);
    setIsLoading(false);
  };

  useEffect(() => {
    reloadPage();
    // const interval = setInterval(async () => await reloadData(), 3000);
    // return () => clearInterval(interval);
  }, [page, pageSize, jobsFilter.from, jobsFilter.to, statusFilter, search]);

  const showResubmitJobModal = (id: string) => {
    openModal(ModalNamesEnum.AdminResubmitJobModal, {
      onSuccess: reloadPage,
      id,
    });
  };

  const showMoveJobToWSModal = (jobId: string, workspaceId: string) => {
    openModal(ModalNamesEnum.AdminMoveJobModal, {
      jobId,
      workspaceId,
      onSuccess: reloadPage,
    });
  };

  const showRefundJobModal = (data: TJobReport) => {
    openModal(ModalNamesEnum.AdminRefundJobModal, {
      onSuccess: reloadPage,
      id: data.id,
      price: data.price,
    });
  };

  const exportCSV = async () => {
    const fromParam = Math.floor(Number(jobsFilter.from) / 1000) || '';
    const toParam = Math.floor(Number(jobsFilter.to) / 1000) || '';
    const jobsReportsForExport = await call(
      AdminService.getJobsReport({
        pageNo: '1',
        pageSize: jobsReports.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        statuses: statusFilter.join(','),
        search,
        from: `${fromParam}`,
        to: `${toParam}`,
      }),
    );
    const headers = [
      'ID',
      'Title',
      'Creator',
      'Email',
      'Organization',
      'Organization Slug',
      'Organization ID',
      'Workspace',
      'Status',
      'Created',
      'Asr used',
      'Edits %',
      'Price',
    ];
    const array = jobsReportsForExport.data.map((data: TJobReport) => {
      return {
        id: data.id,
        title: data.jobName,
        creator: `${data.name} ${data.lastname}`,
        email: `${data.email}`,
        organization: data.organization?.name ?? '',
        organizationSlug: data.organization?.slug ?? '',
        organizationId: data.organization?.id ?? '',
        workspace: data.workspace?.name ?? '',
        status: JOB_STATUSES_ENUM[data.status as keyof typeof JOB_STATUSES_ENUM],
        created: data.createdAt,
        asr: data.duration,
        editsPercent: Math.round(data.editingProgress || 0),
        price: formatCentsPrice(data.price ?? 0),
      };
    });
    return downloadCSV(array, headers);
  };

  return (
    <PageContext.Provider
      value={{
        jobsReports,
        isLoading,
        setIsLoading,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        page,
        setPage,
        reloadPage,
        exportCSV,
        showResubmitJobModal,
        showMoveJobToWSModal,
        showRefundJobModal,
        jobsFilter,
        setJobsFilter,
        statusFilter,
        setStatusFilter,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
