export const fetchAudioDurationFromFileReader = async (file: File) => {
  console.log('fetchAudioDurationFromFileReader', file);

  const reader = new FileReader();

  const au = document.createElement('audio');

  const duration: number = await new Promise((resolve) => {
    reader.onload = async function (e) {
      console.log('reader.onload');
      if (!e.target) {
        console.error('No target');
        resolve(0);
        return;
      }

      const audioElDuration: number = await new Promise((resolve) => {
        if (!e.target?.result) {
          console.error('No target result');
          resolve(0);
          return;
        }

        au.addEventListener(
          'loadedmetadata',
          function () {
            const duration = au.duration;
            console.log('The duration of the audio is of: ' + duration + ' seconds');
            resolve(duration);
          },
          false,
        );

        au.addEventListener(
          'error',
          function () {
            const duration = 0;
            console.log('Error loading metadata. The duration of the audio is of: ' + duration + ' seconds');
            resolve(duration);
          },
          false,
        );

        au.src = e.target.result as string;
      });

      resolve(audioElDuration);
    };

    console.log('reader.readAsDataURL(file)');
    reader.readAsDataURL(file);
  });

  au.remove();
  return duration;
};
