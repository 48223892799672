type TProps = {
  className?: string;
  size?: number;
  progress?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: 'inherit' | 'butt' | 'round' | 'square' | undefined;
};

export const CircularProgress = (props: TProps) => {
  const baseSize = 50;
  const {
    className = 'svg-pi-wrapper',
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = `#ddd`,
    indicatorWidth = 10,
    indicatorColor = `#07c`,
    indicatorCap = `butt`,
  } = props;

  const baseMultiplier = baseSize / size;

  const center = baseSize / 2,
    radius =
      center -
      (trackWidth * baseMultiplier > indicatorWidth * baseMultiplier
        ? trackWidth * baseMultiplier
        : indicatorWidth * baseMultiplier),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <>
      <div className={className} style={{ width: size, height: size }}>
        <svg viewBox={`0 0 ${baseSize} ${baseSize}`} className="svg-pi" style={{ width: size, height: size }}>
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth * baseMultiplier}
          />
          <circle
            className="svg-pi-indicator"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth * baseMultiplier}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>
      </div>
    </>
  );
};
