import styled from 'styled-components';

export const StyledSwitchButtonWrapper = styled.div`
  display: flex;

  .button {
    border-radius: 0;
    background: #f8fafb;
    color: #40608f;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  }

  .active {
    background: #d5def2;
  }

  .userButton {
    border-radius: 5px 0 0 5px;
  }

  .invitationButton {
    border-radius: 0 5px 5px 0;
  }
`;
