import { useEffect } from 'react';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { StyledSelect } from './styles/StyledSelect';
import { StyledText } from './styles/StyledText';
import { Pager } from './components/pager';

export const PagePagination = ({ totalItemsCount, pageSize, setPageSize, page, setPage }: TProps) => {
  const totalPages = Math.ceil((totalItemsCount || 1) / pageSize);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const getShowingPagesText = () => {
    const start = ((page ?? 1) - 1) * pageSize + 1;
    const end = Math.min(start + pageSize - 1, totalItemsCount);
    return `Showing ${start} - ${end} of ${totalItemsCount}`;
  };

  return (
    <Wrapper className="flex items-center p-2 mx-4 fixed bottom-0 z-10 justify-between bg-alabaster">
      {totalPages ? (
        <>
          <div className="dflex">
            <div className="flex align-middle pb-2">
              <span className="text-primary text-sm tracking-wide block self-center">Rows per page</span>
              <StyledSelect
                name="rowsPerPage"
                className="px-2"
                label={String(pageSize)}
                onChange={(e, value) => setPageSize(+value)}
                options={[
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]}
              />
              <StyledText className="pl-3 self-center">{getShowingPagesText()}</StyledText>
            </div>
          </div>

          <div className="paginationBlock">
            <Pager
              currentPage={page}
              pagesCount={totalPages}
              onChange={(page: number) => {
                if (setPage) {
                  setPage(page);
                }
              }}
            />
          </div>
        </>
      ) : null}
    </Wrapper>
  );
};
