import { useState } from 'react';
import styled from 'styled-components';

import { CustomTable } from '@components/Table';
import { DisclosureSection } from '@components/DisclosureSection';
import { TRepositoryStatus } from 'app/types/entities/TRepositoryStatus';
import { TRepository } from 'app/types/entities/TRepository';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  & > div {
    width: 100%;
  }
  table {
    width: 100%;
    .theadTr {
      border-bottom: none;
    }
    thead tr td,
    thead th {
      height: 50px;
    }
  }
`;

const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

type TProps = {
  repositories: TRepository[];
};

export const RepositoriesTable = ({ repositories }: TProps) => {
  const [repositoriesDisclosureOpen, setRepositoriesDisclosureOpen] = useState<boolean>(true);
  const repositoriesSectionTitle = 'Cases ' + (repositories?.length > 0 ? ` (${repositories?.length})` : '');

  const tableHead = [
    {
      headComponent: () => <th />,
      render: (data: TRepositoryStatus) => <CellContent style={{ width: '250px' }}>{data.name}</CellContent>,
    },
    {
      headComponent: () => <th />,
      render: (data: TRepositoryStatus) => (
        <CellContent>
          {data.jobsProcessing !== undefined ? (
            <span style={{ display: 'block' }}>{data.jobsProcessing} jobs processing</span>
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <th />,
      render: (data: TRepositoryStatus) => (
        <CellContent>
          {data.jobsReadyForTranscribing !== undefined ? (
            <span style={{ display: 'block' }}>{data.jobsReadyForTranscribing} jobs ready for editing</span>
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }} />,
      render: (data: TRepositoryStatus) => (
        <CellContent>
          {data.jobsComplete !== undefined ? (
            <span style={{ display: 'block' }}>{data.jobsComplete} completed jobs</span>
          ) : null}
        </CellContent>
      ),
    },
  ];

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={repositoriesSectionTitle}
          open={repositoriesDisclosureOpen}
          setOpenState={setRepositoriesDisclosureOpen}
          loading={false}
        >
          <CustomTable head={tableHead} data={repositories} noHead={true} />
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
