import axios from 'axios';
import axiosRetry from 'axios-retry';

export const client = axios.create({
  // baseURL: `${(import.meta as unknown as { env: { VITE_API_URL: string } }).env?.VITE_API_URL ?? ''}/api` ?? '/api',
  baseURL: '/api',
  withCredentials: true,
});

export const refreshToken = async () => {
  try {
    await client.post('/auth/refresh');
  } catch (e) {
    // console.log('Refresh token failed:', e);
  }
};

client.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('[Axios] Request failed:', error);
    return Promise.resolve({});
  },
);

axiosRetry(client, {
  retries: 3,
  retryDelay: (retryCount) => {
    console.log(`Token expired. Retry attempt: ${retryCount}`);
    return retryCount * 500;
  },
  retryCondition: (error) => {
    if (error.response?.status === 412 && window.location.pathname !== '/account-disabled') {
      window.location.pathname = '/account-disabled';
      return false;
    }
    if (error.response?.status === 418) {
      refreshToken();
      return true;
    }
    return false;
  },
});
