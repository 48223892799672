import { client } from 'app/http';

export const getAvailableMembers = async (id: string) => {
  try {
    const response = await client.get(`/teams/${id}/available-members`);
    return response.data;
  } catch (err) {
    console.log('getTeams request failed:', err);
    return [];
  }
};
