import React, { useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import Papa from 'papaparse';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { useSlugRedirect } from '@hooks/useSlugRedirect';
import { Button } from '@components/form-elements/buttons/Button';
import { TRole } from 'app/types/entities/TRole';
import { InputUpload } from '@components/FileUpload/components/InputUpload';
import { Divider } from '@components/Divider';
import { MinusIcon } from '@components/icons/Minus';
import { OrganizationsService } from 'app/API';
import { EErrorMessages } from '@constants/errorMessages';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  width: 600px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;
  max-height: 80vh;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .buttonWrapper {
    display: flex;
    justify-content: end;
    grid-gap: 10px;
    padding: 20px 0 0;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    overflow-y: auto;
    max-height: 50vh;

    .select {
      height: 40px;
    }

    .add-more {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #40608f;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .note-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #575757;
    margin-top: 15px;
    margin-bottom: 10px;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

interface IRow {
  email: string;
  teamIds: string[];
}

interface ICsvRow {
  Email: string;
  'Team 1': string;
  'Team 2': string;
  'Team 3': string;
  'Team 4': string;
  'Team 5': string;
}

export const InviteUsersByCsvModal = ({ onCancel, onSuccess }: TProps) => {
  const [data, setData] = useState<IRow[]>([]);
  const { call } = useAPI();

  const { organization } = useAuthContext();
  useSlugRedirect(routes.invitations);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await call(
      OrganizationsService.createInvitations({ oid: organization?.id ?? '', requestBody: { invitations: data } }),
      {
        onError: () => {
          toast.error(EErrorMessages.DEFAULT);
        },
      },
    );
    onSuccess();
    toast.success('Invitations was sent');
  };

  const firstOptions: TRole[] = organization?.orgTeams ?? [];

  const teamsIdsByNameMap = new Map((firstOptions || []).map((role) => [role.name.toLowerCase(), role.id]));
  const teamsNamesByIdMap = new Map((firstOptions || []).map((role) => [role.id, role.name]));

  const parseCSV = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function ({ data }: { data: ICsvRow[] }) {
        const validatedData = data
          .map((row: ICsvRow) => {
            const email = row['Email'];

            const teamIds = [
              teamsIdsByNameMap.get(row['Team 1']?.toLowerCase()),
              teamsIdsByNameMap.get(row['Team 2']?.toLowerCase()),
              teamsIdsByNameMap.get(row['Team 3']?.toLowerCase()),
              teamsIdsByNameMap.get(row['Team 4']?.toLowerCase()),
              teamsIdsByNameMap.get(row['Team 5']?.toLowerCase()),
            ].filter((value) => !!value);

            return { email, teamIds };
          })
          .filter((row) => !!row.email && row.teamIds.length > 0);

        if (!validatedData.length) {
          toast.error('Please, set your CSV file according to requirements and try again');
          return;
        }

        const uniqueData: IRow[] = Object.values(
          validatedData.reduce((acc: Record<string, IRow>, obj) => {
            acc[obj.email] = acc[obj.email] || obj;
            return acc;
          }, {}),
        );

        // limit 50 invitations
        setData(uniqueData.slice(0, 50) || []);
      },
    });
  };

  return (
    <Wrapper>
      <h2>Invite Members By CSV</h2>
      <form action="#" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          {!data.length && (
            <a
              href="/example_invitation.csv"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '0.75rem',
                color: '#40608F',
              }}
            >
              Example CSV
            </a>
          )}
          {!data.length && (
            <InputUpload
              accept=".csv"
              onFiles={(files) => {
                if (files.length) {
                  parseCSV(files[0]);
                }
              }}
              multiple={false}
            />
          )}

          {data.map((row, index) => {
            const teamNames = row.teamIds?.map((id) => teamsNamesByIdMap.get(id)).join(', ');
            const counter = index + 1;
            return (
              <div key={row.email}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  {`${counter}. ${row.email} `}
                  {!!teamNames.length && `[${teamNames}]`}
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setData((prev) => prev.filter((value) => value.email !== row.email));
                    }}
                  >
                    <MinusIcon />
                  </div>
                </div>
                {index !== data.length - 1 && <Divider />}
              </div>
            );
          })}
        </div>

        <div className="note-text">* Note: you can invite a maximum of 50 members at once.</div>

        <div className="buttonWrapper">
          <Button variant="outlined" style={{ width: '130px' }} onClick={onCancel}>
            Cancel
          </Button>

          <Button type="submit" style={{ width: '130px' }} disabled={!data.length}>
            Invite Members
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};
