import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { TRoute } from '@routes/types/TRoute';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { TOrganization } from 'app/types/entities/TOrganization';

export const useSlugRedirect = (route: TRoute) => {
  const location = useLocation();
  const { orgSlug, wsSlug } = useParams<{
    orgSlug?: string;
    wsSlug?: string;
    id?: string;
  }>();
  const navigate = useNavigate();
  const isVsAdminSection = location.pathname.includes('/admin/');
  const { workspace, setWorkspace, organization, setOrganization, me } = useAuthContext();

  const changeHistory = (orgSlug: string, wsSlug: string) => {
    if (orgSlug && wsSlug) {
      navigate(route.make(orgSlug, wsSlug));
      return;
    }
    if (orgSlug) {
      navigate(route.make(orgSlug));
      return;
    }
    navigate(route.make(wsSlug));
  };

  const getOrgBySlug = (orgSlug: string) => {
    if (!orgSlug || orgSlug === 'undefined') {
      return organization || me.organizations[0];
    }
    if (organization?.slug === orgSlug) {
      return organization;
    }
    return (me?.organizations || []).find((o: TOrganization) => o.slug.toLowerCase() === orgSlug.toLowerCase());
  };

  const getWsBySlug = (wsSlug: string) => {
    const orgWorkspaces = me.workspaces.filter((w: TWorkspace) => w.organizationId === organization?.id);
    if (!wsSlug || wsSlug === 'undefined') {
      return workspace?.organizationId === organization?.id ? workspace : orgWorkspaces[0];
    }
    return orgWorkspaces.find((ws: TWorkspace) => ws.slug.toLowerCase() === wsSlug.toLowerCase());
  };

  useEffect(() => {
    if (!me.id) {
      return;
    }
    if (isVsAdminSection) {
      const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;
      if (isVsAdmin) {
        return;
      }
    }
    const orgInSlug = getOrgBySlug(orgSlug ?? '');
    // console.log('orgInSlug', orgInSlug);
    // console.log('orgSlug', orgSlug);
    if (!orgInSlug) {
      navigate(routes.requestAccess.make(orgSlug, wsSlug));
      return;
    }
    if (orgInSlug?.id !== organization?.id) {
      setOrganization(orgInSlug);
      changeHistory(orgInSlug?.slug ?? '', wsSlug ?? '');
      return;
    }
    const wsInSlug = getWsBySlug(wsSlug ?? '');
    // console.log('wsInSlug', wsInSlug);
    // console.log('wsSlug', wsSlug);
    if (!wsInSlug) {
      navigate(routes.requestAccess.make(orgSlug, wsSlug));
      return;
    }
    if (wsInSlug?.id !== workspace?.id) {
      setWorkspace(wsInSlug);
      changeHistory(orgInSlug?.slug ?? '', wsInSlug?.slug ?? '');
      return;
    }
  }, [wsSlug, orgSlug]);
};
