import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import { useAuthContext } from '@providers/AuthProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { routes } from '@routes';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TUser } from 'app/types/entities/TUser';
import { EErrorMessages } from '@constants/errorMessages';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';
import { Switch } from '@components/form-elements/Switch';
import { Desktop } from '@components/icons/Desktop';
import { DoubleDone } from '@components/icons/DoubleDone';
import { Done } from '@components/icons/Done';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { PagePagination } from '@components/Pagination';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';
import { FlexCellContent } from './styles/FlexCellContent';
import { SwitchWrapper } from './styles/SwitchWrapper';
import { Avatar } from './styles/Avatar';
import { AvatarName } from './styles/AvatarName';
import { StyledLink } from './styles/StyledLink';
import { OrganizationLink } from './styles/OrganizationsLink';

export const Content = () => {
  const { me } = useAuthContext();
  const { call } = useAPI();
  const {
    users,
    page,
    pageSize,
    setPageSize,
    search,
    setSearch,
    sortingField,
    sortingReverse,
    isLoading,
    setPage,
    changeEnabled,
    toggleSort,
    createUser,
    exportCSV,
    reloadPage,
  } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TUser) => {
        const avatarName = `${data?.name[0]}${data?.lastname[0]}`;
        return (
          <FlexCellContent>
            {data?.avatar ? <Avatar src={data.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('name')}>
          Name
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => (
        <FlexCellContent>
          {data.id === me.id ? (
            <StyledLink to={routes.accountPage.make()}>
              {data.name} {data.lastname}
            </StyledLink>
          ) : (
            <StyledLink to={routes.adminUserDetails.make(data.id)}>
              {data.name} {data.lastname}
            </StyledLink>
          )}
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contacts</td>,
      render: (data: TUser) => (
        <FlexCellContent>
          <CellContent>
            <span style={{ display: 'block' }}>{data.email}</span>
            <span style={{ display: 'block' }}>{data.phone}</span>
          </CellContent>
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => <FlexCellContent>{getAmericanTime(data.createdAt)}</FlexCellContent>,
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Organizations</td>,
      render: (data: TUser) => {
        return (
          <FlexCellContent>
            <CellContent>
              {(data.organizations || []).map((item) => (
                <OrganizationLink key={item.id} to={routes.adminOrganizationDetails.make(item.id)}>
                  {item.name}
                </OrganizationLink>
              ))}
            </CellContent>
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Enabled</td>,
      render: (data: TUser) => {
        let onChange = () => changeEnabled(data);
        if (me.id === data.id) {
          onChange = async () => {};
        }
        return (
          <FlexCellContent>
            <SwitchWrapper>
              <Switch checked={!data.disabled} onChange={onChange} disabled={me.id === data.id} />
            </SwitchWrapper>
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => <SortableHead style={{ width: '1%' }}>How heard about us</SortableHead>,
      render: (data: TUser) => <FlexCellContent>{data.howDidYouHearAboutUs}</FlexCellContent>,
    },
    {
      headComponent: () => <SortableHead style={{ width: '1%' }}>Email verified</SortableHead>,
      render: (data: TUser) => (
        <FlexCellContent className="flex justify-center">
          {data.emailVerified ? <Done width="16px" /> : ''}
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td>Confirmed</td>,
      render: (data: TUser) => (
        <FlexCellContent className="flex justify-center">
          {data.confirmed ? (
            <Done width="16px" />
          ) : (
            <small
              className="border border-[#858DBD] rounded-md p-1 text-white bg-[#858DBD] cursor-pointer"
              onClick={async () => {
                call(AdminService.confirmUser({ userId: data.id }), {
                  onError: () => toast.error(EErrorMessages.DEFAULT),
                  onSuccess: async () => {
                    await reloadPage();
                    toast.success(`User account ${data.email} has successfully confirmed`);
                  },
                });
              }}
            >
              Confirm
            </small>
          )}
        </FlexCellContent>
      ),
    },

    {
      headComponent: () => (
        <td style={{ width: '3%', paddingTop: '6px' }} title="Downloaded & Signed in with Desktop App">
          <Desktop width="16px" />
        </td>
      ),
      render: (data: TUser) => (
        <FlexCellContent className="flex justify-center">
          {data.signedInWithDesktop ? <DoubleDone width="24px" /> : data.downloadedDesktop ? <Done width="16px" /> : ''}
        </FlexCellContent>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Users list - AutoScript Admin</title>
      </Helmet>
      <Header
        title="Users"
        buttonLabel={users.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={users.data?.length ? () => exportCSV() : undefined}
        secondButtonLabel={'Create user'}
        secondButtonOnClick={() => createUser()}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={users.data} />
        <PagePagination
          totalItemsCount={users.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Wrapper>
    </>
  );
};
