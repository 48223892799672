import { TRole } from 'app/types/entities/TRole';
import TeamTag from './TeamTag';

type StateSetter<T> = (newValue: T | ((prevValue: T) => T)) => void;

type TeamsFilterProps = {
  teamsFilterValues: TRole[];
  chosenTeamsFilter: string[];
  setChosenTeamsFilter: StateSetter<any>;
  isOrganizationTeams?: boolean;
};

export default function TeamsFilter({
  teamsFilterValues = [],
  chosenTeamsFilter = [],
  setChosenTeamsFilter,
  isOrganizationTeams = false,
}: TeamsFilterProps) {
  return teamsFilterValues.length ? (
    <>
      <p
        className="teams-assigned-filter"
        style={{ fontWeight: '600', fontSize: '12px', lineHeight: '150%', color: '#858dbd', padding: '20px 0 10px' }}
      >
        Teams assigned to {isOrganizationTeams ? 'organization' : 'workspace'}
      </p>
      <div className="workspace-teams-filter flex overflow-auto">
        <TeamTag
          customStyle={{
            backgroundColor: chosenTeamsFilter.length ? '' : '#D5DEF2',
            color: '#40608F',
            opacity: '1',
          }}
          onClick={() => setChosenTeamsFilter([])}
          team={{ name: 'All' }}
        />

        {teamsFilterValues.map((team) => (
          <TeamTag
            key={team.slug}
            active={!chosenTeamsFilter.length || chosenTeamsFilter.includes(team.slug)}
            onClick={() =>
              setChosenTeamsFilter((prev: string[]) =>
                !prev.includes(team.slug) ? [...prev, team.slug] : [...prev.filter((value) => value !== team.slug)],
              )
            }
            team={team}
          />
        ))}
      </div>
    </>
  ) : null;
}
