import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { refreshToken } from 'app/http';

import { PermissionProvider } from './PermissionProvider/PermissionProvider';

import { TMe } from 'app/types/entities/TMe';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { TOrganization } from 'app/types/entities/TOrganization';
import { EPermission } from 'app/types/enums/EPermission';
import { useAPI } from '@hooks/useAPI';
import { AuthService } from 'app/API';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: (apiCall: string, id: string, opts: any) => void;
  }
}

type TProps = {
  children: ReactNode;
};

type TState = {
  me: TMe;
  updateMe: () => Promise<TMe>;
  clearMe: () => void;
  workspace: TWorkspace | undefined;
  setWorkspace: (arg: TWorkspace) => void;
  organization: TOrganization | undefined;
  setOrganization: (arg: TOrganization) => void;
  setMe: (arg: TMe) => void;
};

const parsePermissions =
  (me: TMe, organization: TOrganization | undefined) =>
  async (permission: EPermission): Promise<boolean> => {
    const org = me.organizations?.find((org) => org.id === organization?.id);

    if (!org) {
      return false;
    }

    return org.permissions[permission];
  };

const AuthContext = createContext<TState>({
  me: {} as TMe,
  updateMe: () => new Promise(() => {}),
  clearMe: () => {},
  workspace: {} as TWorkspace,
  setWorkspace: () => {},
  organization: {} as TOrganization,
  setOrganization: () => {},
  setMe: () => {},
});

export const AuthProvider = ({ children }: TProps) => {
  const { call } = useAPI();
  const location = useLocation();
  const [refreshInterval, setRefreshInterval] = useState<NodeJS.Timeout | string | number>();
  const [me, setMe] = useState<TMe>({} as TMe);
  const [workspace, setWorkspace] = useState<TWorkspace>();
  const [organization, setOrganization] = useState<TOrganization>();

  useEffect(() => {
    const orgWorkspaces = (me.workspaces ?? []).filter((w: TWorkspace) => w.organizationId === organization?.id);
    if (!orgWorkspaces?.find((ws: TWorkspace) => ws.id === workspace?.id)) {
      setWorkspace(orgWorkspaces?.length ? orgWorkspaces[0] : undefined);
    }
  }, [organization, me.workspaces]);

  const updateMe = async () => {
    const data = await call(AuthService.me());
    // console.log('updateMe');

    if (!data?.id) {
      console.error('Unable to retrieve user account (logged out)');
      return;
    }

    if (!data?.organizations) {
      throw new Error('Unable to retrieve user organization details');
    }

    if (window.hj) {
      window.hj('identify', data.id, { email: data.email, name: `${data.name} ${data.lastname}` });
    }

    setMe({ ...data, organizations: [...data.organizations], workspaces: [...data.workspaces] });
    const org = data.organizations.find((o: TOrganization) => o.id === organization?.id);
    if (org) {
      setOrganization(org);
    }
    const ws = data.workspaces.find((w: TWorkspace) => w.id === workspace?.id);
    if (ws) {
      setWorkspace(ws);
    }
    return data;
  };

  useEffect(() => {
    updateOrgFromSlug();
    updateWSFromSlug();
  }, [organization, workspace, me]);

  const updateOrgFromSlug = async () => {
    if (!me.organizations || !me.organizations.length) {
      return;
    }
    const organizationInSlug = me.organizations.find((o) => location.pathname.startsWith(`/${o.slug}/`));
    if (organization && organizationInSlug?.id === organization?.id) {
      return;
    }

    let newOrg = me?.organizations?.[0];
    if (organizationInSlug) {
      newOrg = organizationInSlug;
    }
    setOrganization(newOrg);
  };

  const updateWSFromSlug = async () => {
    if (!organization || !me.organizations || !me.organizations.length) {
      return;
    }
    if (!me.workspaces || !me.workspaces.length) {
      return;
    }
    const workspaceInSlug = me.workspaces.find(
      (w) =>
        location.pathname.startsWith(`/${organization?.slug}/workspaces/${w.slug}/`) &&
        w.organizationId === organization?.id,
    );

    if (workspace && workspaceInSlug?.id === workspace?.id) {
      return;
    }

    if (workspaceInSlug) {
      setWorkspace(workspaceInSlug);
      return;
    }

    const orgWorkspaces = me.workspaces.filter((w: TWorkspace) => w.organizationId === organization?.id);
    if (orgWorkspaces.length) {
      // Fallback to the first workspace in the list
      setWorkspace(orgWorkspaces[0]);
    } else {
      setWorkspace(me.workspaces[0]);
    }
  };

  const clearMe = () => {
    setMe({} as TMe);
  };

  useEffect(() => {
    updateMe();
  }, []);

  useEffect(() => {
    if (refreshInterval && !me.id) {
      clearInterval(refreshInterval);
      setRefreshInterval(undefined);
    }
    if (!refreshInterval && me.id) {
      const interval = setInterval(() => {
        refreshToken();
      }, 200000);
      setRefreshInterval(interval);
    }
  }, [me]);

  return (
    <AuthContext.Provider
      value={{ me, updateMe, clearMe, workspace, setWorkspace, organization, setOrganization, setMe }}
    >
      <PermissionProvider fetchPermission={parsePermissions(me, organization)}>{children}</PermissionProvider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
