import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';

import { Wrapper } from './styles/Wrapper';
import { StyledLink } from './styles/StyledLink';
import { TProps } from './types/TProps';

export const WorkspacesList = ({ workspaces }: TProps) => {
  const { setWorkspace, organization } = useAuthContext();
  const { closeModal } = useModalContext();
  return (
    <Wrapper>
      <div className="header">Workspaces</div>
      <div className="items">
        {workspaces?.map((workspace) => (
          <div key={workspace.id} className="item">
            <div className="title">
              <StyledLink
                onClick={() => {
                  closeModal();
                  setWorkspace(workspace);
                }}
                to={routes.workspaceDetails.make(organization?.slug ?? '', workspace.slug)}
              >
                {workspace.name}
              </StyledLink>
            </div>
            <div className="subtitle">{formatDateMsDsYHI(workspace.createdAt)}</div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};
